import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des consultings
export async function getConsultingData() {
  try {
    const response = await axios.get(`${apiUrl}consulting`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données des consulting :",
      error
    );
    return [];
  }
}

// Récupérer les informations d'un consulting
export async function getOneConsultingData(_id) {
  try {
    const response = await axios.get(`${apiUrl}consulting/${_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations du consulting :",
      error
    );
    throw error;
  }
}

// Supprimer un consulting
export async function deleteConsultingData(_id) {
  try {
    await axios.delete(`${apiUrl}consulting/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// Ajouter un consulting
export async function createConsultingData(consultingData) {
  try {
    const response = await axios.post(`${apiUrl}consulting`, consultingData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du consulting :", error);
    throw error;
  }
}

// Modifier un consulting
export async function updateConsultingData(_id, updatedConsultingData) {
  try {
    const response = await axios.put(
      `${apiUrl}consulting/${_id}`,
      updatedConsultingData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du consulting :", error);
    throw error;
  }
}
