import { useEffect, useState } from "react";

// Import des données
import {
  getConsultingData,
  deleteConsultingData,
  createConsultingData,
  updateConsultingData,
} from "../axios/axiosConsulting";

// Import des fonctions génériques
import {
  deleteItem,
  handleAddItem,
  handleUpdateItem,
} from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES CONSULTINGS

export const useConsultingState = () => {
  //______________________________________________________
  // Affichage des consultings
  const [consultingArray, setConsultingArray] = useState([]);
  const [editModalConsultingIsOpen, setEditModalConsultingIsOpen] =
    useState(false);

  const fetchConsultingData = async () => {
    const consultingData = await getConsultingData();
    setConsultingArray(consultingData);
  };

  useEffect(() => {
    fetchConsultingData();
  }, []);

  //______________________________________________________
  // Suppression d'un consulting
  const deleteConsulting = async (consultingId, consultingNom) => {
    deleteItem(
      consultingId,
      `le consulting ${consultingNom}`,
      deleteConsultingData,
      setConsultingArray
    );
  };

  //______________________________________________________
  // Ajout d'un consulting
  const [nomConsulting, setNomConsulting] = useState("");
  const [imageConsultingUrl, setImageConsultingUrl] = useState("");
  const [altConsultingText, setAltConsultingText] = useState("");
  const [descriptionConsulting, setDescriptionConsulting] = useState("");
  const [lienConsulting, setLienConsulting] = useState("");

  const handleAddConsulting = (e) => {
    handleAddItem(
      e,
      {
        nom: nomConsulting,
        image: imageConsultingUrl,
        alt: altConsultingText,
        description: descriptionConsulting,
        lien: lienConsulting,
      },
      setConsultingArray,
      () => {
        setNomConsulting("");
        setImageConsultingUrl("");
        setAltConsultingText("");
        setDescriptionConsulting("");
        setLienConsulting("");
      },
      createConsultingData
    );
  };

  //______________________________________________________
  // Modification d'un consulting
  const [editedConsulting, setEditedConsulting] = useState({
    nom: "",
    image: "",
    alt: "",
    description: "",
    lien: "",
  });

  const openEditConsultingModal = (consulting) => {
    setEditedConsulting(consulting);
    setEditModalConsultingIsOpen(true);
  };

  const closeEditConsultingModal = () => {
    setEditModalConsultingIsOpen(false);
    setEditedConsulting({
      nom: "",
      image: "",
      alt: "",
      description: "",
      lien: "",
    });
  };

  const handleUpdateConsulting = (e) => {
    handleUpdateItem(
      e,
      editedConsulting,
      updateConsultingData,
      closeEditConsultingModal,
      fetchConsultingData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    consultingArray,
    setConsultingArray,
    deleteConsulting,
    nomConsulting,
    setNomConsulting,
    imageConsultingUrl,
    setImageConsultingUrl,
    altConsultingText,
    setAltConsultingText,
    descriptionConsulting,
    setDescriptionConsulting,
    lienConsulting,
    setLienConsulting,
    handleAddConsulting,
    editModalConsultingIsOpen,
    setEditModalConsultingIsOpen,
    editedConsulting,
    setEditedConsulting,
    openEditConsultingModal,
    closeEditConsultingModal,
    handleUpdateConsulting,
  };
};
